export const toSqm = (value, type) => {
  switch(type) {
      case 'sqm':
          return value;
      case 'sqwah':
          return (value * 4).toFixed(2);
      case 'sqft':
          return (value * 0.092903).toFixed(2);
      case 'rai':
          return (value * 1600).toFixed(2);
      case 'ngan':
          return (value * 400).toFixed(2);
      case 'acre':
          return (value * 4046.86).toFixed(2);
      case 'hectar':
          return (value * 10000).toFixed(2);
      default:
          return value;
  }
}

export const toSqwah = (value, type) => {
  switch(type) {
      case 'sqm':
          return (value / 4).toFixed(2);
      case 'sqwah':
          return value;
      case 'sqft':
          return (value * 0.092903 / 4).toFixed(2);
      case 'rai':
          return (value * 400).toFixed(2);
      case 'ngan':
          return (value * 100).toFixed(2);
      case 'acre':
          return (value * 1011.714).toFixed(2);
      case 'hectar':
          return (value * 2500).toFixed(2);
      default:
          return value;
  }
}

export const toSqft = (value, type) => {
  switch(type) {
      case 'sqm':
          return (value * 10.764).toFixed(2);
      case 'sqwah':
          return (value * 4 * 10.764).toFixed(2);
      case 'sqft':
          return value;
      case 'rai':
          return (value * 17222.5).toFixed(2);
      case 'ngan':
          return (value * 4305.56).toFixed(2);
      case 'acre':
          return (value * 43560).toFixed(2);
      case 'hectar':
          return (value * 107639).toFixed(2);
      default:
          return value;
  }
}

export const toRai = (value, type) => {
  switch(type) {
      case 'sqm':
          return (value / 1600).toFixed(2);
      case 'sqwah':
          return (value / 400).toFixed(2);
      case 'sqft':
          return (value / 17222.5).toFixed(2);
      case 'rai':
          return value;
      case 'ngan':
          return (value / 4).toFixed(2);
      case 'acre':
          return (value * 0.625).toFixed(2);
      case 'hectar':
          return (value * 2.471).toFixed(2);
      default:
          return value;
  }
}

export const toNgan = (value, type) => {
  switch(type) {
      case 'sqm':
          return (value / 400).toFixed(2);
      case 'sqwah':
          return (value / 100).toFixed(2);
      case 'sqft':
          return (value / 4305.56).toFixed(2);
      case 'rai':
          return (value * 4).toFixed(2);
      case 'ngan':
          return value;
      case 'acre':
          return (value * 2.5).toFixed(2);
      case 'hectar':
          return (value * 9.884).toFixed(2);
      default:
          return value;
  }
}

export const toAcre = (value, type) => {
  switch(type) {
      case 'sqm':
          return (value / 4046.86).toFixed(2);
      case 'sqwah':
          return (value * 4 / 4046.86).toFixed(2);
      case 'sqft':
          return (value / 43560).toFixed(2);
      case 'rai':
          return (value * 0.625).toFixed(2);
      case 'ngan':
          return (value * 2.5 / 10).toFixed(2);
      case 'acre':
          return value;
      case 'hectar':
          return (value * 2.471).toFixed(2);
      default:
          return value;
  }
}

export const toHectar = (value, type) => {
  switch(type) {
      case 'sqm':
          return (value / 10000).toFixed(2);
      case 'sqwah':
          return (value * 4 / 10000).toFixed(2);
      case 'sqft':
          return (value / 107639).toFixed(2);
      case 'rai':
          return (value * 0.625 / 2.471).toFixed(2);
      case 'ngan':
          return (value * 2.5 / 9.884).toFixed(2);
      case 'acre':
          return (value / 2.471).toFixed(2);
      case 'hectar':
          return value;
      default:
          return value;
  }
}

export const getBreakdown = (raiValue, originalValue, type) => {
  const rai = Math.floor(raiValue);
  const ngan = Math.floor((raiValue - rai) * 4);
  const sqwah = (rai === 0 && ngan === 0 && type === 'sqwah') ? Math.floor(originalValue) : ((raiValue - rai - ngan / 4) * 400).toFixed(2);
  console.log(originalValue)
  return { rai, ngan, sqwah };
}

export const breakdown = (value, type) => {
  let sqWah = parseFloat(toSqwah(value, type));
  let rai = Math.floor(sqWah / 1600);
  sqWah -= rai * 1600;
  let ngan = Math.floor(sqWah / 400);
  sqWah -= ngan * 400;
  return { rai, ngan, sqWah: sqWah.toFixed(2) };
};
