import React, { useState, useEffect } from 'react';
import './App.css';
import { Card, Row, Col, Typography, Layout, Select, Form, Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { toSqm, toSqft, toRai, toNgan, toAcre, toHectar, getBreakdown, toSqwah } from './conversion';

const { Header, Content, Footer } = Layout;
const { Option } = Select;
const { Title, Link } = Typography;

function Home() {
  const [rnsRaiTotal, setRnsRaiTotal] = useState(0.0);
  const [rnsNganTotal, setRnsNganTotal] = useState(0.0);
  const [rnssqwahTotal, setRnssqwahTotal] = useState(0.0);
  const [totalRai, setTotalRai] = useState(0.0);


  const [selectedUnit, setSelectedUnit] = useState('rns');
  const [inputValue, setInputValue] = useState(0);
  const [titleDeeds, setTitleDeeds] = useState([{ rai: '', ngan: '', sqwah: '' }]);
  const breakdown = getBreakdown(toRai(inputValue, selectedUnit), inputValue, selectedUnit);

  const addTitleDeed = () => {
    setTitleDeeds([...titleDeeds, { rai: '', ngan: '', sqwah: '' }]);
  };
  
  const removeTitleDeed = (index) => {
    const updatedTitleDeeds = titleDeeds.slice();
    updatedTitleDeeds.splice(index, 1);
    setTitleDeeds(updatedTitleDeeds);
  };

  const handleLanguageChange = (lang) => {
    // Add your logic here to update the language throughout your app
    console.log(`Language selected: ${lang}`);
  };


  function updateTitleDeed(value, index, field) {
    const updatedTitleDeeds = [...titleDeeds];
    updatedTitleDeeds[index][field] = value;
    setTitleDeeds(updatedTitleDeeds);
  }
  
    // Define function to handle input value change
    const handleInputChange = e => {
      setInputValue(e.target.value);
    };


    useEffect(() => {
      let totalRai = 0;
      let nganTotal = 0;
      let sqwahTotal = 0;
      titleDeeds.forEach(titleDeed => {
        totalRai += parseFloat(toRai(titleDeed.rai || 0, 'rai')) + parseFloat(toRai(titleDeed.ngan || 0, 'ngan')) + parseFloat(toRai(titleDeed.sqwah || 0, 'sqwah'));
        nganTotal += parseFloat(titleDeed.ngan || 0);
        sqwahTotal += parseFloat(titleDeed.sqwah || 0);
      });
      setTotalRai(totalRai);
      setRnsRaiTotal(totalRai);
      setRnsNganTotal(nganTotal);
      setRnssqwahTotal(sqwahTotal);
    }, [titleDeeds]);


  return (
    <Layout className="layout">
      <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%", margin: '0 auto', background: "#00AA5B" }}>
        <Title level={3} style={{ color: "white", margin: 0 }}>Chanote Calculator</Title>
        <div>
          <Link onClick={() => handleLanguageChange('th')} style={{ color: "white", paddingRight: 8 }}>TH</Link>
          <span style={{ color: "#ffffff87" }}>|</span>
          <Link onClick={() => handleLanguageChange('en')} style={{ color: "white", paddingLeft: 8 }}>EN</Link>
        </div>
      </Header>
      <Content style={{ padding: '0 50px', marginTop: 20, maxWidth: 768, margin: '0 auto' }}>
      

      <Card
        style={{ textAlign: 'center', margin: 'auto', marginTop: 20, maxWidth: 500 }}
        bordered={true}
        title="Input"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Select Conversion">
              <Select style={{ width: '100%' }} defaultValue={selectedUnit} value={selectedUnit} onChange={setSelectedUnit}>
                <Option value="rns">Rai/Ngan/Sq.Wah</Option>
                <Option value="rai">Rai</Option>
                <Option value="ngan">Ngan</Option>
                <Option value="sqwah">Square Wah</Option>
                <Option value="sqm">Square Meter (sq.m)</Option>
                <Option value="sqft">Square Feet</Option>
                <Option value="acre">Acre</Option>
                <Option value="hectar">Hectar</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {selectedUnit !== 'rns' && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label>{selectedUnit}</label>
              <Input type="number" value={inputValue} onChange={handleInputChange} style={{ width: '100%' }} />
            </Col>
          </Row>
        )}


        {selectedUnit === 'rns' && (
          <div>
              {titleDeeds.map((titleDeed, index) => (
                  <Row gutter={16} key={index} style={{ alignItems: 'center' }}>
                      <Col span={7}>
                          <Form.Item label={index === 0 ? "Rai" : null}>
                              <Input value={titleDeed.rai} onChange={(e) => updateTitleDeed(e.target.value, index, 'rai')} />
                          </Form.Item>
                      </Col>
                      <Col span={7}>
                          <Form.Item label={index === 0 ? "Ngan" : null}>
                              <Input value={titleDeed.ngan} onChange={(e) => updateTitleDeed(e.target.value, index, 'ngan')} />
                          </Form.Item>
                      </Col>
                      <Col span={7}>
                          <Form.Item label={index === 0 ? "Sq.Wah" : null}>
                              <Input value={titleDeed.sqwah} onChange={(e) => updateTitleDeed(e.target.value, index, 'sqwah')} />
                          </Form.Item>
                      </Col>
                      <Col span={3}>
                          {titleDeeds.length > 1 && (
                              <Button
                                  onClick={() => removeTitleDeed(index)}
                                  type="danger" shape="circle" icon={<CloseOutlined />}
                              />
                          )}
                      </Col>
                  </Row>
              ))}
              <Row gutter={16}>
                  <Col span={24}>
                      <Button onClick={addTitleDeed}>Add more Title Deed</Button>
                  </Col>
              </Row>
          </div>
        )}
      </Card>


      <br/>
      <br/>


      {selectedUnit === 'rns' && (
        <div>
          <Card
            style={{ textAlign: 'center', margin: 'auto', maxWidth: 500 }}
            bordered={true}
            title={`${inputValue} to ${selectedUnit} is Equal to:`}
          >
            <Row gutter={16} style={{ marginBottom: 10 }}>
              <Col span={24}>
                <Title level={1}>{toSqm(totalRai, 'rai')}</Title>
                <div>Sq Meter</div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Title level={4}>{totalRai}</Title>
                <div>Rai</div>
              </Col>
              <Col span={6}>
                <Title level={4}>{toSqft(totalRai, 'rai')}</Title>
                <div>Sq Feet</div>
              </Col>
              <Col span={6}>
                <Title level={4}>{toAcre(totalRai, 'rai')}</Title>
                <div>Acre</div>
              </Col>
              <Col span={6}>
                <Title level={4}>{toHectar(totalRai, 'rai')}</Title>
                <div>Hectare</div>
              </Col>
            </Row>
          </Card>

          <Card
            style={{ textAlign: 'center', margin: 'auto', marginTop: 20, maxWidth: 500 }}
            bordered={true}
            title="Breakdown"
          >
            <Row gutter={16}>
              <Col span={8}>
                <Title level={4}>{rnsRaiTotal}</Title>
                <div>Rai</div>
              </Col>
              <Col span={8}>
                <Title level={4}>{rnsNganTotal}</Title>
                <div>Ngan</div>
              </Col>
              <Col span={8}>
                <Title level={4}>{rnssqwahTotal}</Title>
                <div>Sq. Wah</div>
              </Col>
            </Row>
          </Card>
        </div>
      )}


    {selectedUnit !== 'rns' && (
      <div>
        <Card
          style={{ textAlign: 'center', margin: 'auto', maxWidth: 500 }}
          bordered={true}
          title={`${inputValue} ${selectedUnit} is Equal to:`}
        >
          <Row gutter={16} style={{ marginBottom: 10 }}>
            <Col span={8}>
              <Title level={4}>{toRai(inputValue, selectedUnit)}</Title>
              <div>Rai</div>
            </Col>
            <Col span={8}>
              <Title level={4}>{toNgan(inputValue, selectedUnit)}</Title>
              <div>Ngan</div>
            </Col>
            <Col span={8}>
              <Title level={4}>{toSqwah(inputValue, selectedUnit)}</Title>
              <div>Sq. Wah</div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Title level={4}>{toSqm(inputValue, selectedUnit)}</Title>
              <div>Sq Meter</div>
            </Col>
            <Col span={6}>
              <Title level={4}>{toSqft(inputValue, selectedUnit)}</Title>
              <div>Sq Feet</div>
            </Col>
            <Col span={6}>
              <Title level={4}>{toAcre(inputValue, selectedUnit)}</Title>
              <div>Acre</div>
            </Col>
            <Col span={6}>
              <Title level={4}>{toHectar(inputValue, selectedUnit)}</Title>
              <div>Hectare</div>
            </Col>
          </Row>
        </Card>

        <Card
          style={{ textAlign: 'center', margin: 'auto', marginTop: 20, maxWidth: 500 }}
          bordered={true}
          title="Breakdown"
        >
          <Row gutter={16}>
            <Col span={8}>
              <Title level={4}>{breakdown.rai}</Title>
              <div>Rai</div>
            </Col>
            <Col span={8}>
              <Title level={4}>{breakdown.ngan}</Title>
              <div>Ngan</div>
            </Col>
            <Col span={8}>
              <Title level={4}>{breakdown.sqwah}</Title>
              <div>Sq. Wah</div>
            </Col>
          </Row>
        </Card>
      </div>
    )}


      </Content>
      <Footer style={{ textAlign: 'center', width: "100%", margin: '0 auto' }}>Chanote Calculator ©2023 Created with ❤️ by Mark & Poppy</Footer>
    </Layout>
  );
}

export default Home;
